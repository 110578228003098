<template>
  <div class="header_wrap">
    <div class="header_box">
      <img class="com_logo" src="~@/assets/ytx_img/logo.png" />
      <div class="com_name">贵州言天下酒业有限公司</div>
      <div class="com_nav">
        <router-link
          target="_blank"
          :to="{
            path: '/company/detail',
            query: {
              id: cid
            }
          }"
          >首页</router-link
        >
        <router-link
          target="_blank"
          :to="{
            path: '/company/productlist',
            query: {
              id: cid
            }
          }"
          >产品展示</router-link
        >
        <router-link
          target="_blank"
          :to="{
            path: '/company/elegantlist',
            query: {
              id: cid
            }
          }"
          >企业荣誉</router-link
        >
        <router-link
          target="_blank"
          :to="{
            path: '/company/teamhonor',
            query: {
              id: cid
            }
          }"
          >企业团队</router-link
        >
        <router-link
          target="_blank"
          :to="{
            path: '/company/newslist',
            query: {
              id: cid
            }
          }"
          >新闻资讯</router-link
        >
        <router-link
          target="_blank"
          :to="{
            path: '/company/contact',
            query: {
              id: cid
            }
          }"
          >联系我们</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ytx_header',
  props: {},
  components: {},
  data() {
    return {
      cid: ''
    };
  },
  created() {
    this.cid = this.$route.query.id;
  },
  mounted() {},
  watch: {},
  methods: {
    // 关闭登录对话框
  }
};
</script>
<style scoped>
.header_wrap {
  background: url(~@/assets/ytx_img/headerbg.jpg) bottom center no-repeat;
  height: 990px;
}
.header_box {
  width: 1200px;
  margin: 0 auto;
  height: 160px;
  position: relative;
}
.header_box .com_logo {
  position: absolute;
  left: 0;
  top: 16px;
}
.header_box .com_name {
  position: absolute;
  left: 130px;
  top: 50px;
  font-size: 23px;
  color: #000;
  font-weight: bold;
}
.header_box .com_nav {
  position: absolute;
  right: 0px;
  top: 50px;
  font-size: 21px;
}
.header_box .com_nav a {
  margin-left: 30px;
  text-decoration: none;
  color: #333;
  outline: 0;
  transition: all 0.3s ease-in-out;
}
.header_box .com_nav a:hover {
  text-decoration: #ff3300;
}
</style>
