<template>
  <div class="footer_wrap">
    <div class="footer_box">
      <div class="f_com_name">贵州言天下酒业有限公司</div>
      <div class="f_linkbox">
        <div class="f_linkcon">
          <ul>
            <li class="f_link_tit">关于我们</li>
            <li>企业简介</li>
            <li>品牌故事</li>
          </ul>
        </div>
        <div class="f_linkcon">
          <ul>
            <li class="f_link_tit">商务合作</li>
            <li>产品展示</li>
          </ul>
        </div>
        <div class="f_linkcon">
          <ul>
            <li class="f_link_tit">客户服务</li>
            <li>友情链接</li>
            <li>常见问题</li>
            <li>更多</li>
          </ul>
        </div>
        <div class="f_linkcon">
          <ul>
            <li class="f_link_tit">找到我们</li>
            <li>地图导航</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="footer_copy">
      &copy 2022-2023 贵州言天下酒业有限公司
      <a href="/">牛豆豆</a> 提供技术支持
    </div>
  </div>
</template>

<script>
export default {
  name: 'ytx_footer',
  props: {},
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {
    // 关闭登录对话框
  }
};
</script>
<style scoped>
.footer_wrap {
  background: #161c25;
  color: #fff;
}
.footer_box {
  width: 1280px;
  margin: 0 auto;
}
.footer_box .f_com_name {
  color: #fff;
  font-size: 32px;
  text-align: center;
  border-bottom: 1px solid #999;
  padding: 26px 0;
}
.footer_box .f_linkbox {
  display: -webkit-flex; /* Safari */
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  margin: 36px auto;
}
.footer_box .f_linkcon {
  width: 180px;
  height: 180px;
  /* background: #ccc; */
}
.footer_box ul {
}
.footer_box li {
  font-size: 16px;
  color: #999;
  padding: 5px;
}
.footer_box li.f_link_tit {
  color: #fff;
  font-weight: bold;
  margin-bottom: 11px;
}
.footer_copy {
  background: #111;
  color: #999;
  font-size: 18px;
  padding: 16px 0;
  text-align: center;
}
.footer_copy a {
  margin-left: 30px;
  color: #ffaa00;
}
.footer_copy a:hover {
  color: #ff3300;
}
</style>
