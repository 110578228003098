<template>
  <div class="wrap">
    <Ytx_header></Ytx_header>
    <div class="comm_box">
      <div class="tit_box">产品展示</div>
      <div class="prod_con_box">
        <div class="prod_con_item" v-for="(a, i) in productlist" :key="a.id">
          <router-link
            target="_blank"
            :to="
              '/product/detail?uniqueNo=' +
                a.uniqueNo +
                '&fromoa=' +
                (isfromoa ? '1' : '')
            "
          >
            <div class="prod_item_tit">{{ a.title }}</div>
            <div class="prod_item_img">
              <el-image :src="a.headImg" fit="cover">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </div>
            <div class="prod_item_price">¥ {{ a.price }}</div>
            <div class="prod_item_buybtn">立即购买</div>
          </router-link>
        </div>
      </div>

      <div class="prod_con_more">
        <router-link
          :to="{
            path: '/company/productlist',
            query: {
              id: cid
            }
          }"
          >更多产品</router-link
        >
      </div>
    </div>
    <div class="comm_box">
      <div class="tit_box">企业简介</div>
      <div class="about_con_box">
        <div class="about_item_img">
          <img src="~@/assets/ytx_img/aboutleft.jpg" />
        </div>
        <div class="about_item_con">
          <p>
            贵州言天下酒业有限公司，是一家纯酱香型白酒企业，主营白酒及预包装食品销售。
          </p>
          <p>
            公司位于贵州省遵义市，遵义被称为“转折之城，会议之都”。是中国著名的红色旅游圣地。同时也是中国三大名酒“茅五剑”之一的茅台酒的故乡。我公司主打品牌“金酱·言天下”系列产品，包装精美，口感醇正，好喝不上头。我公司拥有一流的专业技术队伍，已形成生产、化验、包装、产品展示和销售一条龙经营模式。
          </p>
          <p>
            公司秉承“求真务实、努力创新、勇于拼搏、开拓进取”的企业精神，以“质量求生存、向管理要发展”为经营之本，恪守“诚实守信、信誉第一”的经营之道，赢得了广大消费者的青睐。
          </p>
          <p>
            酱香好酒言天下！我们不忘初心，以传健康饮酒理念，传播酱酒文化，为消费者奉献地道酱香美酒砥砺前行。
          </p>
        </div>
      </div>
    </div>
    <div class="comm_box">
      <div class="tit_box">品牌故事</div>
      <div class="brand_con_box">
        <div class="brand_item_txt">品牌故事...</div>
      </div>
    </div>
    <div class="comm_box">
      <div class="tit_box">新闻资讯</div>
      <div class="news_con_box">
        <div class="news_item_img">
          <img src="~@/assets/ytx_img/aboutleft.jpg" />
        </div>
        <div class="news_item_con">
          <div class="news_item">
            贵州言天下酒业有限公司创建于......主要产品为酱香型白酒......
          </div>
          <div class="news_item">
            贵州言天下酒业有限公司创建于......主要产品为酱香型白酒......
          </div>
          <div class="news_item">
            贵州言天下酒业有限公司创建于......主要产品为酱香型白酒......
          </div>
        </div>
      </div>
    </div>
    <div class="comm_box">
      <div class="tit_box">联系我们</div>
      <div class="contact_con_box">
        <div class="contact_item_img">
          <img src="~@/assets/ytx_img/address_map.jpg" />
        </div>
        <div class="contact_item_con">
          <div class="contact_item">
            服务热线：400-6768-400
          </div>
          <div class="contact_item">
            联系地址：贵州省遵义市红花岗区忠庄街道遵南大道东都凯莱国际35栋2-1
          </div>
        </div>
      </div>
    </div>
    <Ytx_footer></Ytx_footer>
  </div>
</template>
<script>
import { productgetcompanyproductlist } from '@/api/product';

import Ytx_header from '@/components/ytx_header.vue';
import Ytx_footer from '@/components/ytx_footer.vue';
export default {
  name: 'App',
  components: {
    Ytx_header,
    Ytx_footer
  },
  data() {
    return {
      cid: '',
      productlist: []
    };
  },
  mounted() {},
  created() {
    this.cid = this.$route.query.id;
    this.getData();
  },
  methods: {
    getData() {
      var that = this;
      //产品
      productgetcompanyproductlist({
        companyid: this.cid,
        pageIndex: 1,
        pageSize: 3
      }).then(res => {
        if (res.data.code == 1) {
          that.productlist = res.data.result.list;
        }
        console.log(this.productlist);
      });
      //新闻
      //...
    }
  }
};
</script>
<style scoped>
.wrap {
  background: #f1f1f1;
}
.comm_box {
  width: 1280px;
  margin: 0 auto;
  margin-top: 80px;
}
.comm_box .tit_box {
  height: 44px;
  line-height: 44px;
  background: url(~@/assets/ytx_img/barbg.png) top center no-repeat;
  text-align: center;
  font-size: 26px;
  color: #333;
  font-weight: bold;
}
.prod_con_box {
  display: -webkit-flex; /* Safari */
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  margin: 66px auto 36px auto;
}
.prod_con_item {
  width: 390px;
  height: 500px;
  border-radius: 30px;
  box-shadow: 0px 6px 12px 2px rgba(37, 36, 36, 0.1);
  background: url(~@/assets/ytx_img/prodbg.png) top center no-repeat;
}
.prod_item_tit {
  margin: 50px 26px 26px 26px;
  font-size: 21px;
  text-align: center;
  font-weight: bold;
  color: #000;
}
.prod_item_img {
  margin: 0px 30px;
  text-align: center;
}
.prod_item_img .el-image {
  width: 210px;
}
.prod_item_price {
  margin-top: 20px;
  color: #e90c0c;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
}
.prod_item_buybtn {
  margin: 16px auto;
  border: 1px solid #ff0000;
  padding: 10px 6px;
  width: 160px;
  text-align: center;
  font-weight: bold;
  font-size: 23px;
  color: #ff1100;
  border-radius: 6px;
}
.prod_con_more {
  margin: 0 auto;
  width: 200px;
  height: 77px;
  line-height: 77px;
  text-align: center;
  font-size: 21px;
  font-weight: bold;
  background: url(~@/assets/ytx_img/morebg.png) top center no-repeat;
  color: #fff;
}
.prod_con_more a {
  color: #fff;
}
.about_con_box {
  display: -webkit-flex; /* Safari */
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  margin: 66px auto 36px auto;
}
.about_item_img img {
  border-radius: 16px;
}
.about_item_con {
  width: 630px;
  background: #f1f1f1;
  border-radius: 36px;
}
.about_item_con p {
  text-indent: 2em;
  line-height: 1.8em;
  font-size: 17px;
  color: #333;
}
.brand_con_box {
  background: url(~@/assets/ytx_img/brandbg.jpg) top center no-repeat;
  height: 488px;
  margin: 66px auto 26px auto;
  position: relative;
}
.brand_item_txt {
  position: absolute;
  top: 60px;
  right: 60px;
  width: 500px;
  text-indent: 2em;
  line-height: 1.8em;
  font-size: 17px;
  color: #505050;
}
.news_con_box {
  display: -webkit-flex; /* Safari */
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  margin: 66px auto 36px auto;
}
.news_item_img img {
  border-radius: 16px;
}
.news_item_con {
  width: 630px;
  background: #f1f1f1;
  border-radius: 36px;
}
.news_item {
  height: 90px;
  background: #fff;
  box-sizing: border-box;
  padding: 16px 20px;
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 16px;
  border-radius: 6px;
}
.contact_con_box {
  display: -webkit-flex; /* Safari */
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  margin: 66px auto 96px auto;
}
.contact_item_img img {
  border-radius: 16px;
}
.contact_item_con {
  width: 630px;
  background: #f1f1f1;
  border-radius: 36px;
}
.contact_item {
  height: 60px;
  background: #fafafa;
  box-sizing: border-box;
  padding: 18px 20px;
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 16px;
  border-radius: 6px;
}
</style>
