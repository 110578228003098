var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header_wrap"},[_c('div',{staticClass:"header_box"},[_c('img',{staticClass:"com_logo",attrs:{"src":require("@/assets/ytx_img/logo.png")}}),_c('div',{staticClass:"com_name"},[_vm._v("贵州言天下酒业有限公司")]),_c('div',{staticClass:"com_nav"},[_c('router-link',{attrs:{"target":"_blank","to":{
          path: '/company/detail',
          query: {
            id: _vm.cid
          }
        }}},[_vm._v("首页")]),_c('router-link',{attrs:{"target":"_blank","to":{
          path: '/company/productlist',
          query: {
            id: _vm.cid
          }
        }}},[_vm._v("产品展示")]),_c('router-link',{attrs:{"target":"_blank","to":{
          path: '/company/elegantlist',
          query: {
            id: _vm.cid
          }
        }}},[_vm._v("企业荣誉")]),_c('router-link',{attrs:{"target":"_blank","to":{
          path: '/company/teamhonor',
          query: {
            id: _vm.cid
          }
        }}},[_vm._v("企业团队")]),_c('router-link',{attrs:{"target":"_blank","to":{
          path: '/company/newslist',
          query: {
            id: _vm.cid
          }
        }}},[_vm._v("新闻资讯")]),_c('router-link',{attrs:{"target":"_blank","to":{
          path: '/company/contact',
          query: {
            id: _vm.cid
          }
        }}},[_vm._v("联系我们")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }